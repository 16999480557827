import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is User Experience (UX)?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "User Experience or UX encompasses all the things that occur to a user when interacting with a system, product and experience. UX design is the practice of affecting the experience through research, analysis and design of the touchpoints involved in a system."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Does My Business Need  A UX Designer?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "The number one reason why many businesses fail is that they can’t sell their products. Codestaff UX designers will help you research everything from already established products out there to your potential target persona before starting on the visual design for your website. This will help you save money and time and increase your bottom line."
        }
    }
}

const UxDesigner = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> UX Designers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top UX Designers. Top
                                    companies and start-ups choose Codestaff UX Design experts
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top UX Designer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE UX DESIGNERS' banner='/ux-designer.png' bannerAlt='ux designer banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default UxDesigner